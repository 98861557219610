<template>
  <v-container>
    <v-row dense v-if="reportPage">
      <v-col cols="12">
        <h5 class="font-weight-light mb-2 report-title">{{ report.title }} > {{ reportPage.title }}</h5>
      </v-col>
      
      <v-col v-for="(content, contentIndex) in reportPage.content" :cols="getSizingClass(content)" class="d-flex flex-column col-12" :key="'content_' + contentIndex">
        <div :is="content.type" :content="content"/>
      </v-col>
    

    </v-row>
  </v-container>
</template>


<script>
  import TextContent from '@/components/reportPageContent/TextContent.vue'
  import TranslationContent from '@/components/reportPageContent/TranslationContent.vue'
  import TableContent from '@/components/reportPageContent/TableContent.vue'
  import ListContent from '@/components/reportPageContent/ListContent.vue'
  import ProjectResponseSimple from '@/components/reportPageContent/ProjectResponseSimple.vue'
  import Correlation from '@/components/reportPageContent/Correlation.vue'
  import Matrix from '@/components/reportPageContent/Matrix.vue'
  import SingleScore from '@/components/reportPageContent/SingleScore.vue'
  import SubjectScore from '@/components/reportPageContent/SubjectScore.vue'
  import NpsScore from '@/components/reportPageContent/NpsScore.vue'
  import OpenQuestion from '@/components/reportPageContent/OpenQuestion.vue'
  import CountPerScore from '@/components/reportPageContent/CountPerScore.vue'
  import CountPerScoreNoGrade from '@/components/reportPageContent/CountPerScoreNoGrade.vue'
  import CountPerScoreRound from '@/components/reportPageContent/CountPerScoreRound.vue'
  import CustomDivideHundred from '@/components/reportPageContent/CustomDivideHundred.vue'
  import YesNo from '@/components/reportPageContent/YesNo.vue'
  // New visualisation components
  import TableDistribution from '@/components/reportPageContent/TableDistribution.vue'
  import ChooseBetweenTwo from '@/components/reportPageContent/ChooseBetweenTwo.vue'
  import ProjectResponse from '@/components/reportPageContent/ProjectResponseV2.vue'
  import CustomSelect from '@/components/reportPageContent/CustomSelectV2.vue'

  export default {
    name: "ReportPage",
    data () {
      return {
        report: undefined,
        reportPage: undefined,
      }
    },
    components: {
      TextContent,
      TranslationContent,
      TableContent,
      TableDistribution,
      ListContent,
      ProjectResponse,
      ProjectResponseSimple,
      Correlation,
      Matrix,
      SingleScore,
      SubjectScore,
      NpsScore,
      OpenQuestion,
      CountPerScore,
      CountPerScoreNoGrade,
      CountPerScoreRound,
      CustomSelect,
      CustomDivideHundred,
      ChooseBetweenTwo,
      YesNo
    },
    methods: {
      getReportPage() {
        // Get the report
        this.report = this.$store.state.reports.find(report => report.slug === this.$route.params.report)

        // If no report page is set, we're coming from a preview, thus we start at the first page..
        if (!this.$route.params.page) {
          // Get the current report page based on the slug
          if (this.report.type == "chapters") {
            this.reportPage = this.report.content[0].pages[0]
          } else {
            this.reportPage = this.report.content[0]
          }
        } else {
          if (this.report.type == "chapters") {
            this.reportPage = this.report.content.flatMap(chapter => chapter.pages).find(page => page.slug === this.$route.params.page)
          } else {
            this.reportPage = this.report.content.find(page => page.slug === this.$route.params.page)
          }
        }
        // Update the current report
        this.$store.commit('setCurrentReport', this.report.slug)

        if (this.report.settings) {
          this.$store.commit('setReportSettings', this.report.settings)
        }
        else {
          this.$store.commit('setReportSettings', {})
        }
      },
      getSizingClass(content) {
        if (content.size === undefined) {
          return "sm-12"
        }
        else if (content.size === "half") {
          return "sm-6"
        }
        else if (content.size === "third") {
          return "sm-4"
        }
        else if (content.size === "quarter") {
          return "sm-3"
        }
        else if (content.size === "full") {
          return "sm-12"
        }
        else if (content.size === "twothird") {
          return "sm-8"
        }
        else if (content.size === "threequarter") {
          return "sm-9"
        }
        else if (content.size.startsWith("sm")) {
          return content.size
        }
        return "sm-12"
      }
    },
    mounted() {
      this.getReportPage()
    },
    watch: {
      '$route': function () { this.getReportPage() },
      '$i18n.locale': function () { this.getReportPage() }
    }
  }
</script>

<style>
@media print {
  .col-sm-3 {
    max-width: 25%;
  }
  .col-sm-4 {
    max-width: 33.3333333333%;
  }
  .col-sm-6 {
    max-width: 50%;
  }
  .col-sm-8 {
    max-width: 66.6666666666%;
  }
  .col-sm-9 {
    max-width: 75%;
  }

}
</style>